
import React, { useState, useRef, useEffect, useContext } from 'react'

import ReactDOM from 'react-dom'

import "./RecordTable.less"

import Popover from 'bwax-ui/components/legacy/Popover';

import Drawer from 'Client/js/ui/widgets/Drawer';

import { buildSorting, buildCondition } from 'bwax/query/resolveAndRunQuery'

import applyBoolFn from './helpers/applyBoolFn';

import ExcelExport from 'Client/js/ui/widgets/ExcelExport'

import { getFieldDisplay } from 'Client/js/builders/display/fieldDisplays';

import { MemoTable } from 'Client/js/ui/components/DataTable'

import { saveToLocalStorage, getFromLocalStorage } from 'bwax-ui/re/utils/DomUtils.bs'

import RecordFilterInput from 'Client/js/ui/widgets/input/RecordFilterInput';

import { make as IconButton } from 'Client/re/widgets/IconButtonWidget.bs';
// import IconButton from 'Client/js/components/IconButton';

import { make as Input_TableColumnConfig, getStoredHiddenColumns } from 'Client/re/widgets/input/Input_TableColumnConfig.bs';

import Button from './components/Button';

import SmallEditForm from './SmallEditForm';

import { hashCode } from 'bwax/utils'

import { renderActionButton } from './actionButtons';

import getFieldsToUse, {
    transformForFieldDisplay, getFunctionDependedFieldPaths,
} from './helpers/getFieldsToUse';

import Search from './components/Search';
import ClampedText from 'bwax-ui/components/ClampedText';
import setupOperationHandlers from './components/setupOperationHanlders';
import PopoverMenu from './components/PopoverMenu';
import FieldTip from 'Client/js/components/FieldTip';


function getStoredFixedLeftColumns(tabID) {
    const storedKey = `${tabID}.fixedLeftColumns`
    const fixedLeftColumnsStr = getFromLocalStorage(storedKey)
    if (fixedLeftColumnsStr) {
        return JSON.parse(fixedLeftColumnsStr)
    } else {
        return undefined
    }
}

function getStoredPageSize(tabID) {
    const storedKey = `${tabID}.pageSize`
    const pageSizeStr = getFromLocalStorage(storedKey)
    if (pageSizeStr) {
        let pageSize = parseInt(pageSizeStr);
        if (!isNaN(pageSize)) {
            return pageSize
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

function getStoredColumnWidths(tabID) {
    const storedKey = `${tabID}.columnWidths`
    const columnWidthsStr = getFromLocalStorage(storedKey)
    if (columnWidthsStr) {
        return JSON.parse(columnWidthsStr)
    } else {
        return undefined
    }
}

export default function RecordTable(props) {

    const t0 = performance.now();
    const { context, query_config, config, isMain } = props;

    const {
        page_id, dts, route_to, bwax, refreshedAt, refreshPage,
        contextRecordId, contextEntityName,
        onMsg,
    } = context;

    const allEntities = bwax.entities;
    const allDataTypes = bwax.dataTypes;

    const {
        id,  // used to stored the user preference.
        title,

        description,

        fieldColumns,
        excludedFields = ["修改时间", "修改者"],
        showRowNum = true,

        pageSizeOptions = isMain ? [20, 50] : [10, 20, 50],

        operations = [],

        noSearch = false,
        noFilter = false,
        noSetting = false,
        noExport = false,
        noRefresh = false,

        searchFields = undefined,

        allowEditByDefault = false,

        exportConfig,

        topRightButtons = [],
    } = config;


    const tableID = id ? page_id + "::" + id : undefined;

    // The partial variables returned by the record filter:
    const [filter, setFilter] = useState({});
    const [sortBy, setSortBy] = useState({});

    const [searchKeyword, setSearchKeyword] = useState(undefined);

    const [currentPage, setCurrentPage] = useState(1);

    const defaultPageSize = pageSizeOptions && pageSizeOptions.length > 0 ? pageSizeOptions[0] : 10;

    const [pageSize, setPageSize] = useState(tableID ? (getStoredPageSize(tableID) || defaultPageSize) : defaultPageSize);

    const [tableConfig, setTableConfig] = useState({
        hiddenColumns: tableID ? getStoredHiddenColumns(tableID) : [],
        fixedLeftColumns: tableID ? getStoredFixedLeftColumns(tableID) : [],
        columnWidths: tableID ? getStoredColumnWidths(tableID) : {}
    });


    // const pageSize = 20;
    const offset = currentPage ? (currentPage - 1) * pageSize : 0;

    // 1. 生成所有的 path selection
    const [entityName, _] = query_config;

    const entity = allEntities.find(e => e.name == entityName);

    const fields = getFieldsToUse(entity, {
        fieldItems: fieldColumns, excludedFields,
        allEntities, allDataTypes,
        getPathsForFunction: f => getFunctionDependedFieldPaths(f, { bwax, entityName, dts }),

        entity_dict: bwax.entity_dict,
        data_type_dict: bwax.data_type_dict,
        dts
    });

    const fieldPaths = fields.reduce((acc, f) => {
        return [
            ...acc,
            ...f.fieldPaths
        ]
    }, []);

    // get all the operations's disabled / applicableIf functions's dependencies:
    const operationFieldPaths = operations.reduce((acc, o) => {
        const { disabled: disableFn, applicable: applicableFn } = o;

        const dependedFieldPaths = [disableFn, applicableFn].filter(f => !!f).reduce((acc, f) => {
            const paths = getFunctionDependedFieldPaths(f, { bwax, entityName, dts });
            return [...acc, ...paths]
        }, []);

        return [
            ...acc,
            ...dependedFieldPaths
        ]
    }, []);

    const [queriedData, setQueriedData] = useState(undefined);

    const [queryCondition, setQueryCondition] = useState(undefined);
    const [querySort, setQuerySort] = useState(undefined);

    const [tableData, setTableData] = useState(undefined);

    // Custom query 就不支持 sorting，filtering, search
    const isCustomQuery = bwax.isCustomQuery(query_config);


    async function queryData(options, callbackGuard) {

        const [entityName] = query_config;

        // 支持两种不同的 query_config:
        const allFieldPaths = [...fieldPaths, ...operationFieldPaths];

        async function doQuery() {
            if (isCustomQuery) {
                // do custom query
                const queryObj = {
                    query_config,
                    pageSize,
                    offset,
                    outputFieldPaths: [
                        [entityName, allFieldPaths]
                    ]
                }

                const [result, error] = await bwax.customQuery(queryObj, options);

                const queryVars = {};

                return [result, queryVars];

            } else {
                // do standard query

                function buildSearchFields() {
                    function buildEntitySearchField(entity, depth = 0) {
                        return entity.fields.flatMap(f => {
                            if (!f.filterable) {
                                return []
                            }
                            if (f.type == "ShortText" || f.type == "Text" || f.type == "Select") {
                                return [f.name]
                            } else if (f.type == "File") {
                                return [f.name + ".title"]
                            } else if (f.type == "Link") {
                                if (depth == 0) {
                                    return []
                                }
                                const entityNameOrKey = f.options.entity;
                                const linkedEntity = allEntities.find(e => e.name == entityNameOrKey || e.key == entityNameOrKey);
                                if (!linkedEntity) {
                                    return null
                                }
                                return (
                                    linkedEntity.name == "用户" ? [
                                        "昵称",
                                        "手机号用户.手机号"
                                    ] : buildEntitySearchField(linkedEntity, depth - 1)
                                ).map(p => f.name + "." + p)

                            } else {
                                return []
                            }
                        })
                    }

                    const fieldPaths = buildEntitySearchField(entity, 1).map(p => {
                        const depth = p.split(".").length + 1;
                        return { field: p, weight: (5 - depth) * 10 }
                    });

                    return fieldPaths;
                }



                const queryObj = {
                    entityName,
                    query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort

                    // 下面这两个如果都提供了的话，它会自动 merge query_config 里的
                    condition: buildCondition(filter),
                    sort: buildSorting(entity, sortBy),

                    // search 会取代 query_config 里面的
                    search: searchKeyword && searchKeyword.trim().length > 0 ? {
                        keyword: searchKeyword, fields: searchFields || buildSearchFields()
                    } : undefined,

                    // 其他参数
                    pageSize,
                    offset,
                    fieldPaths: allFieldPaths,

                    queryType: "listAll", // 用于 getQueryVars

                };

                const [result, error] = await bwax.listAll(queryObj, options);

                const queryVars = await bwax.getQueryVars(queryObj);

                return [result, queryVars]

            }

        }

        const [result, queryVars] = await doQuery();

        // console.log(">> res", result);

        // TODO error handling
        if (result) {

            // 4. 把结果转换成中文字段名的 obj 并用于实际的 field display:
            // consider the inner fields:
            const tableData = {
                rows: (result.data || []).map(r => {
                    return [transformForFieldDisplay(r, fields, {
                        entity_dict: bwax.entity_dict, data_type_dict: bwax.data_type_dict, entityName: entity.name
                    }), r]
                }),
                totalCount: result.count
            };

            if (callbackGuard === undefined || callbackGuard()) {
                // callback guard is like what stated here 
                // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
                ReactDOM.unstable_batchedUpdates(() => {
                    setQueriedData(result);
                    setTableData(tableData);
                    setQueryCondition(queryVars.q0Condition || []);
                    setQuerySort(queryVars.q0Sort || []);
                })
            }
        }

    }

    const refresh = () => {
        if (refreshPage) {
            refreshPage();
        } else {
            queryData({})
        }
    }

    const reload = (callbackGuard) => {
        queryData({ forceRefreshing: true }, callbackGuard);
    };

    useEffect(() => {
        let isActive = true;
        queryData({}, () => {
            return isActive
        });
        return () => { isActive = false }
    }, [
        bwax, JSON.stringify(query_config), JSON.stringify(filter),
        JSON.stringify(sortBy), searchKeyword, currentPage, pageSize,
        refreshedAt
    ]);

    // 5. 构建 table columns，给出对应的 value renderer

    const valueRenderer = (field, index) => {
        const display = getFieldDisplay(field);
        const configParams = field.config && field.config.displayParams ? field.config.displayParams : {};
        const customParams = (() => {
            if (field.type === 'Image') {
                return {
                    width: 40,
                    height: 40,
                    processor: 'small',
                    ...configParams
                }
            }
            return configParams
        })()

        const Component = display.component;
        return (value, record) => {

            const env = { allEntities, allDataTypes, bwax };

            return <Component value={value} customParams={{
                ...customParams,
                // linkPattern: linkPattern && linkPattern.replace(":id", id)
            }} field={field} env={{
                ...env, onMsg
            }} recordValue={record[1]} // [_, rawValue]
            />
        }
    }

    const otherColumns = fields.map((f, index) => {
        const customWidths = {
            "Select": 120,
            "File": 200,
            "RichText": 300,
            "JSON": 300,
            "JSONB": 300,
            "ShortText": 160,
            "Link": 140,
            "Text": 240,
            "Integer": 90,
            "Number": 120,
            "Date": 120,
            "DateTime": 160,
            "Boolean": 100,
            "Image": 120,
            "YQYLTypePosterSetting": 360
        }
        const customAligns = {
            "Number": "right",
            "Integer": "right",
        }
        const config = f.config || {}
        let baseWidth = config.width || customWidths[f.type] || 240
        if (f.multivalued) {
            baseWidth = baseWidth * 1.5;
        }

        const name = f.cname || f.name;
        const desc = f.desc;

        // 有文字又有tip，至少 200 px 吧
        // TODO 更智能的初始化 width
        const width = name && name.length > 5 && (desc && desc != name) & baseWidth < 200 ? 200 : baseWidth;

        function isEditable([_record, rawValue]) {
            if (config.editingAllowed) {
                // if any base fields are editable:

                const { baseFields } = f;
                const hasUpdatableField = baseFields.some(f => f.updatable);
                if (hasUpdatableField) {
                    return applyBoolFn(config.editingAllowed, rawValue, { bwax, entityName });
                } else {
                    return false
                }
            }
            return allowEditByDefault;
        }

        return {
            name,
            path: f.path || f.name,
            desc,
            width,
            dataType: f.type,
            align: customAligns[f.type] || "left",
            sortable: f.sortable === true && !isCustomQuery,
            renderValue: valueRenderer(f, index),
            getValue: ([record, rawValues], index) => {
                return record[name]
            },
            isEditable,
            renderEdit: (value, [record, rawValue], index, closeEdit) => {
                // return <div>Inline Edit</div>
                const { baseFields } = f;

                // 暂时把 inputParams 传给自己 , 其实应该独立配置的（应该配置在 field 级别）
                // TODO https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/876

                const updatableFields = baseFields.filter(uf => uf.updatable).map(uf => {
                    if (uf.name === f.name) {
                        return {
                            ...uf,
                            config: f.config
                        }
                    } else {
                        return uf
                    }
                });

                // initial form value
                const initialValues = updatableFields.reduce((acc, f) => {
                    return {
                        ...acc,
                        [f.name]: rawValue[f.name]
                    }
                }, {});


                const props = {
                    id: record.id, fields: updatableFields, initialValues, closeEdit,
                    dataEntity: entity, allEntities, allDataTypes, bwax, reload: refresh
                }
                return <SmallEditForm {...props} />
            }
        }
    }).filter(
        // c => identifyingField === undefined || identifyingField.name !== c.name
        c => true
    );

    const OperationHandlers = setupOperationHandlers({ bwax, entity, reload: refresh, route_to });

    let operationColumn = undefined;

    if (operations.length > 0) {
        // let width = Object.keys(ops).length > 1 ? 200 : 120;
        let width = operations.length > 1 ? 96 : 80;
        operationColumn = {
            name: '操作',
            width,
            fixed: 'right',
            align: 'center',
            getValue: r => r,
            renderValue: r => {

                if (!r) { return null }

                const [record, rawValue] = r;

                const operationButtons = operations.map((operation, index) => {
                    const {
                        label, confirm, buttonType, color,
                        disabled: disabledFn, applicable: applicableFn,
                        op
                    } = operation;

                    const applicable = applicableFn ? applyBoolFn(applicableFn, rawValue, { bwax, entityName }) : true;
                    if (!applicable) {
                        return undefined;
                    }

                    const disabled = disabledFn ? applyBoolFn(disabledFn, rawValue, { bwax, entityName }) : false;

                    const handler = OperationHandlers[op.__t__];
                    const onClick = () => {
                        if (handler) {
                            handler(op.__v__, { id: record && record.id })
                        } else {
                            console.log("No handler", op);
                        };
                    }

                    return [
                        label,
                        (inMenu = false) => {
                            const props = {
                                label, confirm, buttonType,
                                color, disabled, onClick, inMenu
                            }
                            return <Button {...props} />
                        }
                    ]

                }).filter(x => !!x);

                // 1. 取第一个显示成 Button
                // 2. 其他（如果有的话），则收在“更多”菜单里面

                const [firstButton, ...otherButtons] = operationButtons.filter(x => !!x);

                if (!firstButton) {
                    return null
                } else {

                    const getIcon = () => {
                        return (
                            <div className="operation-menu-icon">
                                <PopoverMenu items={otherButtons.map(([label, renderer]) => [label, renderer(true)])} />
                            </div>
                        )
                    }

                    return (
                        <div className="table-button-box">
                            {firstButton[1](false)}
                            {getIcon()}
                        </div>
                    )
                }
            }
        }
    }


    const columns = [
        ...otherColumns,
        ...(operationColumn ? [operationColumn] : [])
    ];


    const tableColumns = getTableColumns(columns, showRowNum, { currentPage, pageSize })

    const [lastData, setLastData] = useState(undefined);
    const shouldReload = useRef(true);

    useEffect(() => {
        let isActive = true;
        if (tableData !== undefined) {
            if (shouldReload.current === true) {
                shouldReload.current = false; // 只 reload 一次就好了。
                // // Reload 的逻辑，可能需要优化 TODO
                // setTimeout(() => {
                //     reload(() => isActive)
                // }, 4);

            }
            setLastData(tableData);
        } else {
            // /// 遇到了 undefined，这意味着当前是新加载在的数据，不需要 reload
            // shouldReload.current = false
        }
        return () => {
            isActive = false
        }
    }, [queriedData])

    const searchInput = () => (
        <Search
            onKeywordChange={value => {
                setSearchKeyword(value);
                setCurrentPage(1);
            }}
            style={{ width: 108, borderRadius: 8 }}
        />
    );

    const filterButton = () => (
        <FilterButton
            entityName={entityName} fields={fields} filter={filter}
            {...{
                entityName, fields, filter, contextRecordId, contextEntityName
            }}
            applyChange={filter => {
                ReactDOM.unstable_batchedUpdates(() => {
                    setFilter(filter);
                    setCurrentPage(1);
                })
            }}
            env={{ allEntities, allDataTypes }}
        />
    )

    const tableSetting = () => (tableID ? (
        <Input_TableColumnConfig
            params={{
                tip: "隐藏数据列",
                fieldsToUse: fields,
                tabID: tableID
            }}
            value={tableConfig}
            onChange={v => setTableConfig(v)}
        />
    ) : null);

    const exportButton = () => (
        <ExportButton
            scene={page_id + "::" + (id || hashCode(query_config)) + + entityName}
            entityName={entityName}
            fields={fields.filter(f => {
                if(tableConfig && tableConfig.hiddenColumns) {
                    return tableConfig.hiddenColumns.indexOf(f.cname) === -1
                }
                return true;
            })}
            conditions={queryCondition || []}
            sorting={querySort || []}
            filter={filter}
            exportAsExcel={exportConfig}
            env={{ allEntities, allDataTypes }}
        />
    )

    const refreshButton = () => (
        <IconButton params={{
            icon: "sync",
            tip: "刷新数据",
            onClick: () => reload()
        }} />
    )

    const topRightButtonList =
        <>
            {noSearch || isCustomQuery ? null : searchInput()}
            {noFilter || isCustomQuery ? null : filterButton()}
            {noSetting ? null : tableSetting()}
            {noExport || isCustomQuery ? null : exportButton()}
            {noRefresh ? null : refreshButton()}

            <MemoActionButtons
                data={queriedData} // used for memo
                buttons={topRightButtons}
                dataEntity={entity}
                reload={refresh}
                context={context}
            />
        </>


    // 6. 调用 MemoTable

    function renderHead() {
        return (
            <div className="widget-head topbar">
                <div className="widget-title">
                    <span className="title-line">{title ? <ClampedText text={title} /> : null}</span>
                    {description ? <ClampedText className="description-line" text={description}></ClampedText> : null}
                </div>
                <div className="top-right-bar"> {topRightButtonList} </div>
            </div>
        )
    }

    const t1 = performance.now();

    // console.log("Record Table", t1 - t0);

    return (
        <div className="admin--record-table admin-widget">
            {renderHead()}
            <MemoTable
                data={
                    tableData || lastData
                }
                dataName={"dataName"}
                currentPage={currentPage}
                tableConfig={tableConfig}

                operationColumn={operationColumn}

                tableColumns={tableColumns}
                pagination={{
                    pageSize
                }}
                tableID={tableID}

                getRowKey={(index, v) => {
                    if (v && v[1]) {
                        return v[1].id
                    } else {
                        return index
                    }
                }}

                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                onPageSizeChange={ps => {
                    shouldReload.current = true; // try reload
                    saveToLocalStorage(`${tableID}.pageSize`, ps + "");

                    ReactDOM.unstable_batchedUpdates(() => {
                        setPageSize(ps)
                        setCurrentPage(1);
                    })
                }}

                setTableConfig={config => {
                    setTableConfig(prev => ({
                        ...prev,
                        ...config
                    }));
                    if (config && tableID) {
                        const { fixedLeftColumns, columnWidths } = config;
                        // save local storage
                        fixedLeftColumns ? saveToLocalStorage(`${tableID}.fixedLeftColumns`, JSON.stringify(fixedLeftColumns)) : null;
                        columnWidths ? saveToLocalStorage(`${tableID}.columnWidths`, JSON.stringify(columnWidths)) : null;
                    }


                }}
                onSort={({ orders }) => {
                    const sortBy = Object.keys(orders).reduce((acc, k) => {
                        const path = tableColumns.find(c => c.name == k).path  // 假定肯定可以找到
                        return {
                            [path]: orders[k],
                            ...acc
                        }
                    }, {});
                    setSortBy(sortBy);
                }}
                onPageChange={p => {
                    shouldReload.current = true; // try reload
                    setCurrentPage(p)
                }}
            />
        </div>
    )
}

export function create(props) {
    return <RecordTable {...props} />
}


function getTableColumns(columns, showRowNum, { currentPage, pageSize }) {

    const baseNum = (currentPage - 1) * pageSize;

    const initialColumns = showRowNum ? [{
        title: '#',
        key: '#',
        getValue: (_, index) => <ClampedText style={{ opacity: 0.6, fontSize: 11 }} text={index + 1 + (baseNum || 0)}></ClampedText>,
        width: 48,
        fixed: 'left'
    }] : []

    const tableColumns = columns.map(col => {
        const columnDef = typeof (col) === 'string' ? { name: col } : col

        const { name, desc, } = columnDef;

        const clampedName = <ClampedText text={name} />;

        return ({
            ...columnDef,
            key: name,
            title: !!desc && desc !== name ? (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    {clampedName}
                    <FieldTip tip={desc} />
                </div>
            ) : clampedName,
        })
    })

    return [
        ...initialColumns,
        ...tableColumns
    ]

}

const MemoActionButtons = React.memo(({
    buttons,
    dataEntity, contextEntity, contextRecordId,
    reload,
    context,
}) => {
    // 根据 actions 的定义返回一系列按钮：
    const renderedButtons = buttons.map(b => renderActionButton(b, { dataEntity, reload, context }));

    return (
        <>
            {renderedButtons}
        </>
    )


}, (prev, current) => prev.data == current.data)


function ExportButton({ scene, entityName, conditions = [], sorting = [], fields = [], exportAsExcel = {}, env, filter }) {

    const [visible, setVisible] = useState(false);

    if (exportAsExcel.hidden == true) {
        return null
    }

    const excludedColumns = exportAsExcel.excludedColumns || [];

    const actualFieldsToUse = fields.filter(f => {
        return excludedColumns.indexOf(f.name) == -1
    })

    const fieldNames = actualFieldsToUse.map(f => f.path || f.name)


    // prepare the properties of new Export Job.
    // 主要是为了解决 moment value 的问题
    const additionalFilters = filter ? JSON.parse(JSON.stringify(filter)) : filter;

    const additionalFilterNames = Object.keys(additionalFilters || {});
    const desc = additionalFilterNames.length > 0 ? "筛选条件: " + additionalFilterNames.join("; ") : undefined;

    const { allEntities, allDataTypes } = env;

    return (
        <>
            <IconButton params={{
                icon: "export",
                tip: "导出Excel",
                onClick: () => {
                    setVisible(true)
                }
            }} {...env} />
            <Drawer
                params={{
                    visible,
                    nohead: true,
                    hide: () => setVisible(false),

                    content: (
                        <ExcelExport
                            params={{
                                entityName,
                                desc,
                                conditions,
                                sorting,
                                fieldNames,
                                scene,
                            }}
                            allEntities={allEntities}
                            allDataTypes={allDataTypes}
                        />
                    )
                }}
                {...env}
            />
        </>
    )
}


export function FilterButton(props) {

    const { filter, fields, entityName, contextEntityName, contextRecordId, env, applyChange } = props;

    const conditionFields = fields.filter(
        f => (
            (f.type === 'ShortText' || f.type === 'Select' || f.type === 'Link' || f.type === 'Text') ||
            ((f.type === 'Boolean' || f.type === 'Date' || f.type === 'DateTime' || f.type === 'Number') && !f.multivalued)
        ) && f.filterable
    ).map(f => ({
        cname: f.cname || f.name,
        name: f.path || f.name,
        type: f.type,
        multivalued: f.multivalued,
        options: f.options,
        required: f.required
    }));

    // 1. 一个 icon 按钮

    // 2. 点击后 Popover

    // 3. Popover 里面是一个

    function renderFilter() {
        return (
            <div style={{ padding: "1rem" }}>
                <RecordFilterInput
                    params={{
                        conditionFields,
                        entityName,
                        contextEntityName,
                        contextRecordId
                    }}
                    value={filter}
                    onChange={v => {
                        applyChange(v)
                    }}
                    {...env} />
            </div>

        )
    }
    const color = Object.keys(filter).length === 0 ? "N80" : "B60";

    return (
        <Popover content={renderFilter()} trigger={"click"} positions={["bottom"]} align="end" padding={8}>
            <div>
                <IconButtonWrapper {...{
                    icon: "funnel-plot",
                    tip: "筛选数据",
                    color
                }} />
            </div>
        </Popover>
    )

}

function IconButtonWrapper(props) {
    return <IconButton params={props} />
}

